import React, { useContext } from "react";
import required from "../../libs/required";
import { constantsContext } from "../context";

export default (constants) =>
	(Component = required`Component`) =>
	(props) => {
		const currentConstants = useContext(constantsContext) || {};
		Object.assign(currentConstants, constants);

		return (
			<constantsContext.Provider value={currentConstants}>
				<Component {...currentConstants} {...props} />
			</constantsContext.Provider>
		);
	};
