import { loadableReady } from "@loadable/component";
import ReactDOM from "react-dom";
import React, { useEffect } from "react"; // eslint-disable-line no-unused-vars
import { BrowserRouter as Router } from "react-router-dom"; // eslint-disable-line no-unused-vars
import getResources from "./getResources.js";

import App from "../../App.jsx";

import "@utils/Array";
import "@utils/Date";
import "@utils/String";

// const { NODE_ENV } = process.env;
// import Hydrate from "../../Hydrate";
// import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
// import theme from "@src/styles/theme";

function Main({ routes, staticContext }) {
	useEffect(() => {
		const jssStylesElem = document.querySelector("#jss-server-side");
		if (jssStylesElem) {
			jssStylesElem.parentElement.removeChild(jssStylesElem);
		}
	}, []);

	return (
		<Router>
			<App routes={routes} staticContext={staticContext} />
		</Router>
	);
}

// function MainV2({ currentRoute, component, staticContext }) {
// 	useEffect(() => {
// 		// const jssStylesElem = document.querySelector("#jss-server-side");
// 		// if (jssStylesElem) {
// 		// 	jssStylesElem.parentElement.removeChild(jssStylesElem);
// 		// }
// 	}, []);

// 	return (
// 		<ThemeProvider theme={theme}>
// 			<StylesProvider injectFirst>
// 				<Hydrate route={currentRoute}>{component}</Hydrate>
// 			</StylesProvider>
// 		</ThemeProvider>
// 	);
// }

const { ENV } = process.env;

export default async function (routes) {
	let configuredRoutes = [];

	if (ENV !== "production") {
		console.log({ configuredRoutes });
		console.log({ routes });
	}
	configuredRoutes = configuredRoutes.concat(routes);

	const appStateElem = document.querySelector("#app-page-state");
	const staticContext = JSON.parse(appStateElem.innerHTML || "{}") || {};
	const { currentRoutePath } = staticContext;
	if (!currentRoutePath) {
		throw new Error("currentRoutePath must be set in the staticContext");
	}

	const currentRouteIndex = configuredRoutes.findIndex(
		(route) => route.path === currentRoutePath
	);

	const currentRoute = configuredRoutes[currentRouteIndex];
	const type = currentRoute.ssr ? "hydrate" : "render";

	try {
		configuredRoutes[currentRouteIndex] = await getResources({ currentRoute });
	} catch (err) {
		throw err;
	}

	if (ENV !== "production") {
		console.log({ configuredRoutes });
		console.log({ currentRoute });
	}
	// const { hydrateComponents } = await currentRoute
	// 	.component(currentRoute)
	// 	.load();
	// console.log({ hydrateComponents });
	loadableReady(() => {
		// console.log({ hydrateComponents });
		// hydrateComponents.forEach((component, i) => {
		// 	console.log("YOYOY");
		// 	//if (i > 0) return;
		// 	setTimeout(() => {
		// 		console.log(component.props.id);
		// 		ReactDOM.hydrate(
		// 			<MainV2
		// 				currentRoute={currentRoute}
		// 				staticContext={staticContext}
		// 				component={component}
		// 			/>,
		// 			document.getElementById(component.props.id)
		// 		);
		// 	});

		// 	console.log("WHAT");
		// });
		// <Main routes={configuredRoutes} staticContext={staticContext} />;
		ReactDOM[type](
			<Main routes={configuredRoutes} staticContext={staticContext} />,
			document.getElementById("root")
		);
	});
}
