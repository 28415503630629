export default [
	{
		path: "/pros/appointments",
		exact: true,
		policy: "providerAuth",
		component: "Appointments",
		mui: true,
	},
	{
		path: "/pros/appointments/create",
		exact: true,
		policy: "providerAuth",
		component: "AppointmentCreate/AppointmentCreate",
		mui: true,
	},
];
