import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [{value: () => import(/* webpackPreload: true */ "./@libs/findInjectedProAccount.js"), path: "./@libs/findInjectedProAccount.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/redirectBasedOnAffinity.js"), path: "./@libs/redirectBasedOnAffinity.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/SearchFilter.js"), path: "./@libs/SearchFilter.js"}];
export const styles = [{value: () => import(/* webpackPreload: true */ "./Search/@styles/Search.styles.js"), path: "./Search/@styles/Search.styles.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@styles/SearchMap.styles.js"), path: "./SearchMap/@styles/SearchMap.styles.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@styles/SearchMapFilterDialog.styles.js"), path: "./SearchMap/@styles/SearchMapFilterDialog.styles.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@styles/SearchMapProItem.styles.js"), path: "./SearchMap/@styles/SearchMapProItem.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./Search/@stores/Search.store.js"), path: "./Search/@stores/Search.store.js"}, {value: () => import(/* webpackPreload: true */ "./Search/@stores/SearchFilterDialog.store.js"), path: "./Search/@stores/SearchFilterDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Search/@stores/SearchFilterQuestion.store.js"), path: "./Search/@stores/SearchFilterQuestion.store.js"}, {value: () => import(/* webpackPreload: true */ "./Search/@stores/SearchFunnel.store.js"), path: "./Search/@stores/SearchFunnel.store.js"}, {value: () => import(/* webpackPreload: true */ "./Search/@stores/SearchProList.store.js"), path: "./Search/@stores/SearchProList.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchGoogleMap.store.js"), path: "./SearchMap/@stores/SearchGoogleMap.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchMap.store.js"), path: "./SearchMap/@stores/SearchMap.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchMapFilterDialog.store.js"), path: "./SearchMap/@stores/SearchMapFilterDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchMapFilterQuestion.store.js"), path: "./SearchMap/@stores/SearchMapFilterQuestion.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchMapMap.store.js"), path: "./SearchMap/@stores/SearchMapMap.store.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@stores/SearchMapProItems.store.js"), path: "./SearchMap/@stores/SearchMapProItems.store.js"}];
export const extensions = [{value: () => import(/* webpackPreload: true */ "./Search/@.extension.js"), path: "./Search/@.extension.js"}, {value: () => import(/* webpackPreload: true */ "./SearchMap/@.extension.js"), path: "./SearchMap/@.extension.js"}];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
