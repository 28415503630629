import React from "react";
import { compose } from "recompose";

import withStyles from "@framework/src/libs/withStyles";
import withConstants from "@framework/src/libs/withConstants";
import withStores from "@framework/src/libs/withStores";

export default (route, components) => {
	return withStores(route.extensions?.stores || {})(
		withConstants(route.constants)(
			withStyles(
				Object.assign({}, route.extensions?.styles || {}, route.styles || {})
			)(components.default)
		)
	);
	// return withConstants(route.constants)(
	// 	withStyles(route.styles)(components.default)
	// );
};
