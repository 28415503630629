import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	getCookieByName,
	setCookieByName,
	searchToObject,
} from "@utils/Browser";

const UTMTracking = (props) => {
	const targets = { utm_campaign: 1, utm_source: 1, utm_content: 1, utm_id: 1 };
	const location = useLocation();

	useEffect(() => {
		const req = {
			query: searchToObject(location.search),
		};

		const result = ((obj) => {
			for (const key in req.query) {
				if (!req.query.hasOwnProperty(key)) continue;
				if (targets[key] && targets.hasOwnProperty(key))
					obj[key] = req.query[key];
			}
			return obj;
		})({});

		for (const key in targets) {
			if (!result.hasOwnProperty(key)) continue;
			if (getCookieByName(key) && result[key] === getCookieByName(key))
				delete result[key];
		}

		for (const key in result) {
			if (!result.hasOwnProperty(key)) continue;
			setCookieByName(key, result[key], { maxAge: 1000 * 60 * 60 * 24 * 60 });
		}
	}, [location]);

	return <React.Fragment>{props.children}</React.Fragment>;
};

export default UTMTracking;
