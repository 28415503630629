export default [
	{
		path: "/admin/questions",
		exact: true,
		policy: "superAdminAuth",
		component: "AdminQuestions",
		mui: true,
		load: true,
	},
];
