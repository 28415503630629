import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
export const controllers = [];
export const models = [];
export const libs = [];
export const styles = [];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/Modals/CreateBusinessModelGroupModal.store.js"), path: "./@stores/Modals/CreateBusinessModelGroupModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Modals/CreateSubscriptionModal.store.js"), path: "./@stores/Modals/CreateSubscriptionModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Modals/EditBusinessModelGroupModal.store.js"), path: "./@stores/Modals/EditBusinessModelGroupModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Modals/EditSubscriptionModal.store.js"), path: "./@stores/Modals/EditSubscriptionModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/StripePortalConfigurations.store.js"), path: "./@stores/StripePortalConfigurations.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Subscriptions.store.js"), path: "./@stores/Subscriptions.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Tables/BusinessModelGroupsTable.store.js"), path: "./@stores/Tables/BusinessModelGroupsTable.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/Tables/SubscriptionsTable.store.js"), path: "./@stores/Tables/SubscriptionsTable.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
