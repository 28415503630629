export default [
	{
		path: "/join",
		exact: true,
		ssr: false,
		component: "SignUp",
		middleware: ["cityLookUp"],
		mui: true,
	},
];
