export default [
	{
		path: "/landing",
		exact: true,
		ssr: true,
		component: "Landing",
		middleware: ["cityLookUp"],
		mui: true,
	},
	{
		path: "/signup",
		exact: true,
		ssr: false,
		component: "Hottie/Hottie",
		mui: true,
	},
];
