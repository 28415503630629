import MainService from "../Main";
import { getCookieByName } from "@utils/Browser";
import * as operations from "./Authentication.graphql";

import axios from "axios";

const { NODE_ENV } = process.env;

class AuthenticationService extends MainService {
	constructor(options) {
		super(options);
		this.patch(operations);
	}

	googleConnect(variables = {}, options = {}) {
		const { googleConnectValidation: validation } = this.validations;

		if (validation)
			try {
				validation.validateSync(variables);
			} catch (err) {
				if (NODE_ENV !== "production") console.error(err);
				return Promise.reject(err);
			}

		return axios(
			Object.assign(options, {
				method: "post",
				url: "/google/connect",
				data: Object.assign(variables, {
					_csrf: getCookieByName("_csrfToken"),
				}),
			})
		)
			.then((response) => response.data)
			.catch((e) => {
				console.error("Error logging in with google:", e.response);
				throw { message: `${e.response.status} - ${e.response.data}` };
			});
	}

	fbConnect(variables = {}, options = {}) {
		const { fbConnectValidation: validation } = this.validations;

		if (validation)
			try {
				validation.validateSync(variables);
			} catch (err) {
				if (NODE_ENV !== "production") console.error(err);
				return Promise.reject(err);
			}

		return axios(
			Object.assign(options, {
				method: "post",
				url: "/facebook/connect",
				data: Object.assign(variables, {
					_csrf: getCookieByName("_csrfToken"),
				}),
			})
		)
			.then((response) => response.data)
			.catch((e) => {
				console.error("Error logging in with facebook:", e.response);
				throw { message: `${e.response.status} - ${e.response.data}` };
			});
	}

	login(variables = {}, options = {}) {
		const { loginValidation: validation } = this.validations;

		if (validation)
			try {
				validation.validateSync(variables);
			} catch (err) {
				if (NODE_ENV !== "production") console.error(err);
				return Promise.reject(err);
			}

		return axios(
			Object.assign(options, {
				method: "post",
				url: "/auth/login",
				data: Object.assign(variables, {
					_csrf: getCookieByName("_csrfToken"),
				}),
			})
		)
			.then((response) => response.data)
			.catch((e) => {
				console.error("Error logging in:", e.response);
				throw { message: `${e.response.status} - ${e.response.data}` };
			});
	}

	logout(variables = {}, options = {}) {
		return axios(
			Object.assign(options, {
				method: "post",
				url: "/logout",
				data: Object.assign(variables, {
					_csrf: getCookieByName("_csrfToken"),
				}),
			})
		)
			.then((response) => response.data)
			.catch((e) => {
				console.error("Error logging out:", e);
				throw e;
			});
	}
}

export default AuthenticationService;
