export default [
	{
		path: "/pros/settings",
		policy: "providerAuth",
		component: "Settings/Settings",
		exact: true,
		mui: true,
	},
	{
		path: "/pros/settings/saved-replies",
		policy: "providerAuth",
		exact: true,
		component: "SavedReplies/SavedReplies",
		mui: true,
	},
	{
		path: "/pros/settings/payments",
		policy: "providerAuth",
		exact: true,
		component: "Payments/Payments",
		mui: true,
	},
	{
		path: "/pros/settings/notifications",
		policy: "providerAuth",
		exact: true,
		component: "Notifications/Notifications",
		mui: true,
	},
	{
		path: "/pros/settings/badges",
		policy: "providerAuth",
		exact: true,
		component: "Badges/Badges",
		mui: true,
	},
	{
		path: "/pros/settings/account",
		policy: "providerAuth",
		exact: true,
		component: "Account/Account",
		mui: true,
	},
	{
		path: "/pros/settings/locations",
		policy: "providerAuth",
		exact: true,
		component: "Locations/Locations",
		mui: true,
	},
	{
		path: "/pros/settings/business-details",
		policy: "providerAuth",
		exact: true,
		component: "BusinessDetails/BusinessDetails",
		mui: true,
	},
	{
		path: "/pros/settings/online-booking",
		policy: "providerAuth",
		exact: true,
		component: "OnlineBooking/OnlineBooking",
		mui: true,
	},
	{
		path: "/pros/settings/resources",
		policy: "providerAuth",
		exact: true,
		component: "Resources/Resources",
		mui: true,
	},
	{
		path: "/pros/settings/tips",
		policy: "providerAuth",
		exact: true,
		component: "TipsSettings/TipsSettings",
		mui: true,
	},
	{
		path: "/pros/settings/taxes",
		policy: "providerAuth",
		exact: true,
		component: "Taxes/Taxes",
		mui: true,
	},
];
