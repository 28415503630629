export default [
	{
		path: "/client/bookings",
		exact: true,
		component: "Bookings",
		load: true,
	},
	{
		path: "/client/bookings/details",
		exact: true,
		//policy: "providerAuth",
		component: "BookingsDetails/BookingsDetails",
	},
];
