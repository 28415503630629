export default [
	{
		path: "/pro/onboarding/progress-aperture",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/ApertureProgress/ApertureProgress",
		stepId: ["aperture-progress-profile", "aperture-progress-promote"],
	},
	{
		path: "/pro/onboarding/tutorial-aperture",
		exact: false,
		load: true,
		policy: "providerAuth",
		component: "Templates/ApertureTutorial/ApertureTutorial",
		stepId: [
			"aperture-tutorial-pricing",
			"aperture-tutorial-leads",
			"aperture-tutorial-payment",
		],
	},
	{
		path: "/pro/services/:serviceKey/budget",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/Budget/Budget",
		stepId: ["budget"],
	},
	{
		path: "/pro/services/:serviceKey/creditcard",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/CreditCard/CreditCard",
		stepId: ["credit-card"],
	},
	{
		path: "/pro/services/:serviceKey/business-hours",
		policy: "providerAuth",
		load: true,
		middleware: ["cityLookUp"],
		component: "Templates/BusinessHours/BusinessHours",
		stepId: ["business-hours"],
		groupId: "time",
	},
	{
		path: "/pro/business-hours",
		policy: "providerAuth",
		load: true,
		middleware: ["cityLookUp"],
		component: "Templates/BusinessHours/BusinessHours",
	},
	{
		path: "/pro/services/:serviceKey/day-and-time",
		exact: true,
		load: true,
		policy: "providerAuth",
		middleware: ["cityLookUp"],
		component: "Templates/DayAndTime/DayAndTime",
		stepId: ["day-and-time"],
		groupId: "time",
	},
	{
		path: "/pro/onboarding/:serviceKey/business-info",
		policy: "providerAuth",
		load: true,
		middleware: ["cityLookUp"],
		component: "Templates/BusinessInfo/BusinessInfo",
		stepId: ["business-info"],
	},
	{
		path: "/pro/onboarding/:serviceKey/business-intro",
		policy: "providerAuth",
		load: true,
		component: "Templates/BusinessIntro/BusinessIntro",
		stepId: ["business-intro"],
	},

	{
		path: "/pro/services/:serviceKey/job-preference",
		policy: "providerAuth",
		load: true,
		component: "Templates/JobPreferences/JobPreference",
		stepId: ["job-preferences"],
	},
	{
		path: "/pro/onboarding/more-services",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/MoreServices/MoreServices",
		stepId: ["more-services"],
	},
	{
		path: "/pro/setup/personal-info",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/PersonalInfo/PersonalInfo",
		stepId: ["personal-info"],
	},
	{
		path: "/pro/services/:serviceKey/pricing",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Templates/Pricing/Pricing",
		stepId: ["pricing"],
	},
	{
		path: "/pro/services/:serviceKey/pricing/wedge/:wedgeAnswerId",
		policy: "providerAuth",
		component: "Templates/Pricing/Pricing",
		load: true,
		exact: true,
		stepId: ["pricing"],
	},
	{
		path: "/pro/onboarding/:serviceKey/profile-image",
		policy: "providerAuth",
		load: true,
		component: "Templates/ProfileImage/ProfileImage",
		stepId: ["profile-image"],
	},
	{
		path: "/pro/services/:serviceKey/travel-preference",
		component: "Templates/TravelPreference/TravelPreference/TravelPreference",
		load: true,
		policy: "providerAuth",
		stepId: ["travel-preference"],
		groupId: "travel",
	},
	{
		path: "/pro/services/:serviceKey/:userType(client|provider)/geo-preference",
		load: true,
		policy: "providerAuth",
		component: "Templates/TravelPreference/GeoPreference/GeoPreference",
		groupId: "travel",
	},
	{
		path: "/pro/services/:serviceKey/lead-prices",
		load: true,
		policy: "providerAuth",
		component: "Templates/LeadPrices/LeadPrices",
		stepId: ["lead-prices"],
	},
	{
		path: "/pro/services/:serviceKey/finish",
		load: true,
		policy: "providerAuth",
		component: "Templates/Finish/Finish",
		stepId: ["finish"],
	},
	{
		path: "/pro/services/special-offer",
		load: true,
		policy: "providerAuth",
		component: "Templates/CreditPackage/CreditPackage",
		stepId: ["credit-package"],
	},
	{
		path: "/pro/services/:serviceKey/subscriptions",
		load: true,
		policy: "providerAuth",
		component: "Templates/Subscriptions/Subscriptions",
		stepId: ["subscriptions"],
	},
];
