// IE Polyfill
if (!Array.prototype.fill) {
  Object.defineProperty(Array.prototype, 'fill', {
    value: function(value) {

      // Steps 1-2.
      if (this === null) {
        throw new TypeError('this is null or not defined');
      }

      var O = Object(this);

      // Steps 3-5.
      var len = O.length >>> 0;

      // Steps 6-7.
      var start = arguments[1];
      var relativeStart = start >> 0;

      // Step 8.
      var k = relativeStart < 0 ?
        Math.max(len + relativeStart, 0) :
        Math.min(relativeStart, len);

      // Steps 9-10.
      var end = arguments[2];
      var relativeEnd = end === undefined ?
        len : end >> 0;

      // Step 11.
      var final = relativeEnd < 0 ?
        Math.max(len + relativeEnd, 0) :
        Math.min(relativeEnd, len);

      // Step 12.
      while (k < final) {
        O[k] = value;
        k++;
      }

      // Step 13.
      return O;
    }
  });
}

// IE Polyfill
// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this === null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}


/**
 * Checks if passed in type is an Object.
 *
 * @param {*} obj - type to check
 * @returns {boolean} true if it is an object
 */
const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]';


/**
 * Checks to see if a string is present in an array, case insensitive.
 *
 * @param {String} str
 * @returns {*} false if there is no match, string in array if there is
 */
Array.prototype.contains = function (str) { // eslint-disable-line no-extend-native
  if (!str) {
    return false;
  }

  let i = this.length;
  while (i--) {
    if (this[i].trim().toLowerCase() === str.trim().toLowerCase()) {
      return this[i];
    }
  }
  return false;
};


/**
 * Removes all duplicate entries from an array.
 *
 * @returns {Array} new array with out duplicates
 */
Array.prototype.unique = function () { // eslint-disable-line no-extend-native
  const temp = {};
  const r = [];

  if (isObject(this[0])) {
    this.forEach(obj => { temp[JSON.stringify(obj)] = true });

    for (const key in temp) {
      if (!temp.hasOwnProperty(key)) continue;
      r.push(JSON.parse(key));
    }

  } else {
    this.forEach(item => { temp[item] = true; });

    for (const key in temp) {
      if (!temp.hasOwnProperty(key)) continue;
      r.push(key);
    }
  }

  return r;
};


/**
 * Trims white space from array elements, removes space strings.
 *
 * @returns {Array} clean
 */
Array.prototype.clean = function () { // eslint-disable-line no-extend-native
  const clean = [];

  let i = this.length;
  while (i--) {
    this[i] = this[i].trim();
    if (this[i] && this[i].length) {
      clean.push(this[i]);
    }
  }

  return clean.reverse();
};
