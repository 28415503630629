import states from "../../../../libs/states";

export default [
	{
		path: `/:state(${states.join("|")})/:city/:serviceSlug/:slug`,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "Profile",
	},
];
