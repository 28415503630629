import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	"@keyframes keyframe_loading_dots": {
		"0%,80%,100%": {
			opacity: 0.3,
		},
		"30%,50%": {
			opacity: 1,
		},
	},
	"loadingDots": {
		margin: "0px auto",
		textAlign: "center",
		position: "absolute",
		whiteSpace: "nowrap",
		top: "50%",
		left: "50%",
		transform: "translateX(-50%) translateY(-50%)",
		zIndex: 1499,
	},
	"dot1": {
		borderRadius: "100%",
		display: "inline-block",
		animationName: `$keyframe_loading_dots`,
		animationDuration: "0.8s",
		animationIterationCount: "infinite",
		animationTimingFunction: "linear",
		animationFillMode: "both",
		verticalAlign: "middle",
		width: 12,
		height: 12,
		marginRight: 8,
		backgroundColor: theme.palette.primary.main,
		animationDelay: "-0.3s",
	},
	"dot2": {
		borderRadius: "100%",
		display: "inline-block",
		animationName: `$keyframe_loading_dots`,
		animationDuration: "0.8s",
		animationIterationCount: "infinite",
		animationTimingFunction: "linear",
		animationFillMode: "both",
		verticalAlign: "middle",
		width: 12,
		height: 12,
		marginRight: 8,
		backgroundColor: theme.palette.primary.main,
		animationDelay: "-0.15s",
	},
	"dot3": {
		borderRadius: "100%",
		display: "inline-block",
		animationName: `$keyframe_loading_dots`,
		animationDuration: "0.8s",
		animationIterationCount: "infinite",
		animationTimingFunction: "linear",
		animationFillMode: "both",
		verticalAlign: "middle",
		width: 12,
		height: 12,
		backgroundColor: theme.palette.primary.main,
	},
}));

const Loading = observer(({ store, children, ...props }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			{store.show ? (
				store.Component ? (
					<store.Component {...props} />
				) : (
					<div className={classes.loadingDots}>
						<Box
							display={"flex"}
							height={"100%"}
							width={"100%"}
							alignItems={"center"}
							justifyContent={"center"}
						>
							<div className={classes.dot1} />
							<div className={classes.dot2} />
							<div className={classes.dot3} />
						</Box>
					</div>
				)
			) : (
				children
			)}
		</React.Fragment>
	);
});

Loading.propTypes = {
	store: PropTypes.object.isRequired,
	children: PropTypes.node,
};

export default Loading;
