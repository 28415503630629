export default [
	{
		path: "/booking",
		exact: true,
		ssr: false,
		component: "BookingFunnel",
		mui: true,
	},
	{
		path: "/booking/staff",
		exact: true,
		ssr: false,
		component: "BookingFunnelStaff/BookingFunnelStaff",
		mui: true,
	},
	{
		path: "/booking/multi-staff",
		exact: true,
		ssr: false,
		component: "BookingFunnelStaffMulti/BookingFunnelStaffMulti",
		mui: true,
	},
	{
		path: "/booking/time",
		exact: true,
		ssr: false,
		component: "BookingFunnelTime/BookingFunnelTime",
		mui: true,
	},
	{
		path: "/booking/multi-time",
		exact: true,
		ssr: false,
		component: "BookingFunnelTimeMulti/BookingFunnelTimeMulti",
		mui: true,
	},
	{
		path: "/booking/checkout",
		exact: true,
		ssr: false,
		component: "BookingFunnelCheckout/BookingFunnelCheckout",
		mui: true,
	},
	{
		path: "/booking/cart",
		exact: true,
		ssr: false,
		component: "BookingFunnelMyCart/BookingFunnelMyCart",
		mui: true,
	},
];
