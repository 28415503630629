export const adjective = {
	singular: "service provider",
	plural: "service providers",
};

export const verb = {
	singular: "working",
};

export const job = {
	singular: "customer",
	plural: "customers",
};
