import React from "react";
import loadable from "@loadable/component";

const Header = loadable(() =>
	import("@components/Dashboard/Pro/Funnel/Shared/Header")
);
import { getParameterByName } from "@utils/Browser";

export default ({ children, ...props }) => {
	const aperture_id = getParameterByName("aperture_id");

	return (
		<React.Fragment>
			{aperture_id === "pricing" && (
				<Header label={"Add your settings"} {...props} />
			)}
			{aperture_id === "payment" && (
				<Header label={"Weekly budget"} {...props} />
			)}
			{aperture_id === "leads" && (
				<Header label={"Add your settings"} {...props} />
			)}
			{children}
		</React.Fragment>
	);
};
