import React, { useContext } from "react";
import { stylesContext } from "../context";
import required from "../../libs/required";

const useStyles = (name = required`name`, props = {}) => {
	const styles = useContext(stylesContext);
	if (!styles[name]) return {};
	return styles[name]();
};

export default useStyles;
