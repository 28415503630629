const routes = [
	{
		path: "/pros/:serviceKey/upgrade",
		exact: true,
		policy: "providerAuth",
		component: "Upgrade",
		mui: true,
	},
];

export default routes;
