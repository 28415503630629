import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/ReviewApproval.styles.js"), path: "./@styles/ReviewApproval.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/AdminReviews.store.js"), path: "./@stores/AdminReviews.store.js"}, {value: () => import(/* webpackPreload: true */ "./Tables/ReviewApproval/@stores/ReviewApprovalTable.store.js"), path: "./Tables/ReviewApproval/@stores/ReviewApprovalTable.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
