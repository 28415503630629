export default [
	{
		path: "/pros/sales",
		exact: true,
		policy: "providerAuth",
		component: "Sales",
		mui: true,
	},
	{
		path: "/pros/sales/memberships-list",
		exact: true,
		policy: "providerAuth",
		component: "MembershipsList/MembershipsList",
		mui: true,
	},
	{
		path: "/pros/sales/transactions",
		exact: true,
		policy: "providerAuth",
		component: "Transactions/Transactions",
		mui: true,
	},
	{
		path: "/pros/sales/invoices",
		exact: true,
		policy: "providerAuth",
		component: "Invoices/Invoices",
		mui: true,
	},
	{
		path: "/pros/sales/invoices/new",
		exact: true,
		policy: "providerAuth",
		component: "InvoicesNew/InvoicesNew",
		mui: true,
	},
	{
		path: "/pros/sales/estimates",
		exact: true,
		policy: "providerAuth",
		component: "Estimates/Estimates",
		mui: true,
	},
];
