export default [
	{
		path: "/",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "Home",
		mui: true,
	},
];
