export default [
	{
		path: "/near-me",
		exact: true,
		ssr: true,
		seo: true,
		component: "NearMe/NearMe",
	},
	/* 	{
		path: "/sitemap/locations",
		exact: true,
		ssr: true,
seo: true,
		component: "Locations/Locations",
	},
	{
		path: "/sitemap/profiles",
		exact: true,
		ssr: true,
seo: true,
		component: "Profiles/Profiles",
	}, */
	{
		path: "/:name(state_sitemap|city_sitemap|article_sitemap|city_service_sitemap|costguide_sitemap|profile_sitemap|service_sitemap|sitemap|state_sitemap).xml",
		exact: true,
		ssr: true,
		seo: true,
		component: "Xml/Xml",
	},
];
