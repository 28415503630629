export default [
	{
		path: "/pros/services",
		exact: true,
		load: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "ServiceList/ServiceList",
		mui: true,
	},
	{
		path: "/pros/services/add",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "ServiceAdd/ServiceAdd",
		mui: true,
	},
	{
		path: "/pros/services/:serviceKey/settings",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "ServiceSettings/ServiceSettings",
		mui: true,
	},
	{
		path: "/pros/services/hide-business",
		exact: true,
		load: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "HideBusiness/HideBusiness",
		mui: true,
	},
	{
		path: "/pros/services/price-list",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "PriceList/PriceList",
		mui: true,
	},
];
