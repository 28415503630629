import React from "react";
require("intersection-observer");

import { Switch, Route as RouteServer, useHistory } from "react-router-dom";
import shortid from "shortid";
import Controller from "./Controller";

import ScrollToTop from "@components/Global/ScrollToTop";
import { disableFragmentWarnings } from "graphql-tag";

import Stores from "./src/stores";
import RootStore from "./src/stores/RootStore";

import {
	rootStoreContext,
	staticContext as StaticContext,
} from "./src/context";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "@src/styles/theme";

import fixRouteOrder from "@utils/fixRouteOrder";

disableFragmentWarnings();

import UTMTracking from "@libs/framework/UTMTracking";
import * as featureToggleService from "feature-toggle-service";
import { isBrowser } from "@utils/Browser";
import client from "@framework/apollo/client";
import batch from "@framework/apollo/batch";

import markup from "../../src/markup";

import {
	registerMarkup,
	registerApolloClient,
	registerApolloBatchClient,
} from "@bundlejs/markup";

if (isBrowser) {
	registerMarkup(markup);
	registerApolloClient(
		client({
			ssr: false,
			window,
			defaultOptions: {
				query: {
					fetchPolicy: "network-only",
					errorPolicy: "all",
				},
				mutate: {
					fetchPolicy: "no-cache",
					errorPolicy: "all",
				},
			},
		})
	);
	registerApolloBatchClient(
		batch({
			ssr: false,
			window,
			defaultOptions: {
				query: {
					fetchPolicy: "network-only",
					errorPolicy: "all",
				},
				mutate: {
					fetchPolicy: "no-cache",
					errorPolicy: "all",
				},
			},
		})
	);
}

const { SITE_NAME } = process.env;
const req = require.context(
	"../../environments",
	true,
	/(feature-toggle)\.(json)$/
);

req.keys().forEach((key) => {
	const site = key.replace(/\.\/(.*)\/(feature-toggle)\.(json)/, "$1");
	if (site !== SITE_NAME) return;
	const featureToggleConfig = req(key);
	featureToggleService.set(featureToggleConfig);
});

const App = ({ routes = [], serverError = false, staticContext = {} }) => {
	if (!Array.isArray(routes)) routes = [routes];

	const history = useHistory();
	const statusCode = staticContext?.statusCode || serverError?.statusCode;

	const rootStore = new RootStore({
		staticContext,
		stores: Stores,
		history,
	});

	routes = fixRouteOrder(routes);

	return (
		<ThemeProvider theme={theme}>
			<rootStoreContext.Provider value={rootStore}>
				<StaticContext.Provider value={staticContext}>
					<UTMTracking>
						<div className="App">
							<ScrollToTop>
								<Controller
									statusCode={statusCode}
									hash={shortid.generate()}
									routes={routes}
									staticContext={staticContext}
								>
									<Switch>
										{routes.map(({ component, ...route }) => {
											return (
												<RouteServer
													render={() => {
														const Component = component(route);
														return <Component />;
													}}
													{...route}
													key={route.path}
												/>
											);
										})}
									</Switch>
								</Controller>
							</ScrollToTop>
						</div>
					</UTMTracking>
				</StaticContext.Provider>
			</rootStoreContext.Provider>
		</ThemeProvider>
	);
};

export default App;
