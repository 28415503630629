import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/@module/controller.js"), path: "./Inbox/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./InboxList/@module/controller.js"), path: "./InboxList/@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./Inbox/@styles/Inbox.styles.js"), path: "./Inbox/@styles/Inbox.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@styles/PhotosDialog.styles.js"), path: "./Inbox/Modals/@styles/PhotosDialog.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@styles/ReportDialog.styles.js"), path: "./Inbox/Modals/@styles/ReportDialog.styles.js"}, {value: () => import(/* webpackPreload: true */ "./InboxList/@styles/InboxList.styles.js"), path: "./InboxList/@styles/InboxList.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./Inbox/@stores/Inbox.store.js"), path: "./Inbox/@stores/Inbox.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/@stores/InboxChatMessages.store.js"), path: "./Inbox/@stores/InboxChatMessages.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/@stores/InboxChatSend.store.js"), path: "./Inbox/@stores/InboxChatSend.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/@stores/InboxProfilePhone.store.js"), path: "./Inbox/@stores/InboxProfilePhone.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/InboxProfileDialog.store.js"), path: "./Inbox/Modals/@stores/InboxProfileDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/LeadDetailsDialog.store.js"), path: "./Inbox/Modals/@stores/LeadDetailsDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/PhotosDialog.store.js"), path: "./Inbox/Modals/@stores/PhotosDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/ReportDialog.store.js"), path: "./Inbox/Modals/@stores/ReportDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/ReviewsDialog.store.js"), path: "./Inbox/Modals/@stores/ReviewsDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./Inbox/Modals/@stores/ShowPhoneDialog.store.js"), path: "./Inbox/Modals/@stores/ShowPhoneDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./InboxList/@stores/InboxCardItem.store.js"), path: "./InboxList/@stores/InboxCardItem.store.js"}, {value: () => import(/* webpackPreload: true */ "./InboxList/@stores/InboxList.store.js"), path: "./InboxList/@stores/InboxList.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
