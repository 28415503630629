import loadable from "@loadable/component";

export default loadable(() =>
	import("@components/Dashboard/Pro/Shared/Background")
);
export const props = {
	drawerHidden: true,
	toggleNotification: false,
	preventClaimProfileDialog: true,
};
