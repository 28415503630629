import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceList/@module/controller.js"), path: "./ServiceList/@module/controller.js"}];
export const models = [{value: () => import(/* webpackPreload: true */ "./@module/model.js"), path: "./@module/model.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceList/@module/model.js"), path: "./ServiceList/@module/model.js"}];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/ServiceList.styles.js"), path: "./@styles/ServiceList.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./HideBusiness/@stores/HideBusiness.store.js"), path: "./HideBusiness/@stores/HideBusiness.store.js"}, {value: () => import(/* webpackPreload: true */ "./HideBusiness/Modals/ConfirmationModal/@stores/ConfirmationModal.store.js"), path: "./HideBusiness/Modals/ConfirmationModal/@stores/ConfirmationModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceAdd/@stores/ServiceAdd.store.js"), path: "./ServiceAdd/@stores/ServiceAdd.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceList/@stores/ServiceList.store.js"), path: "./ServiceList/@stores/ServiceList.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceList/ServiceCard/@stores/ServiceCard.store.js"), path: "./ServiceList/ServiceCard/@stores/ServiceCard.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceList/ServiceCard/@stores/ServiceCardAction.store.js"), path: "./ServiceList/ServiceCard/@stores/ServiceCardAction.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceSettings/@stores/ServiceSettings.store.js"), path: "./ServiceSettings/@stores/ServiceSettings.store.js"}, {value: () => import(/* webpackPreload: true */ "./ServiceSettings/Modals/RemoveServiceDialog/@stores/RemoveServiceDialog.store.js"), path: "./ServiceSettings/Modals/RemoveServiceDialog/@stores/RemoveServiceDialog.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
