import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* webpack-strip-block:removed */
export const libs = [{value: () => import(/* webpackPreload: true */ "./@libs/Quote.js"), path: "./@libs/Quote.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/Reviews.js"), path: "./@libs/Reviews.js"}];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Profile.styles.js"), path: "./@styles/Profile.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/ProfileActionPanel.styles.js"), path: "./@styles/ProfileActionPanel.styles.js"}];
export const constants = [{value: () => import(/* webpackPreload: true */ "./@constants/bettercare/ProfileViewModel.json"), path: "./@constants/bettercare/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/dealflow/ProfileViewModel.json"), path: "./@constants/dealflow/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/fash/ProfileViewModel.json"), path: "./@constants/fash/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/homeguide/ProfileViewModel.json"), path: "./@constants/homeguide/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawful/ProfileViewModel.json"), path: "./@constants/lawful/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawnmasters/ProfileViewModel.json"), path: "./@constants/lawnmasters/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lessons/ProfileViewModel.json"), path: "./@constants/lessons/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/talo/ProfileViewModel.json"), path: "./@constants/talo/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/thervo/ProfileViewModel.json"), path: "./@constants/thervo/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/trustedcare/ProfileViewModel.json"), path: "./@constants/trustedcare/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/tutors/ProfileViewModel.json"), path: "./@constants/tutors/ProfileViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/vety/ProfileViewModel.json"), path: "./@constants/vety/ProfileViewModel.json"}];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/Profile.store.js"), path: "./@stores/Profile.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileActionsPanel.store.js"), path: "./@stores/ProfileActionsPanel.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileFooter.store.js"), path: "./@stores/ProfileFooter.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileLeadPanelPrices.store.js"), path: "./@stores/ProfileLeadPanelPrices.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileLicense.store.js"), path: "./@stores/ProfileLicense.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileLinks.store.js"), path: "./@stores/ProfileLinks.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileLocationHours.store.js"), path: "./@stores/ProfileLocationHours.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileLocationHoursDialog.store.js"), path: "./@stores/ProfileLocationHoursDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfilePhotos.store.js"), path: "./@stores/ProfilePhotos.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileResume.store.js"), path: "./@stores/ProfileResume.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileReviews.store.js"), path: "./@stores/ProfileReviews.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileReviewsDialog.store.js"), path: "./@stores/ProfileReviewsDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ProfileReviewsList.store.js"), path: "./@stores/ProfileReviewsList.store.js"}, {value: () => import(/* webpackPreload: true */ "./Modals/ProfilePhotosDialog/@stores/ProfilePhotosDialog.store.js"), path: "./Modals/ProfilePhotosDialog/@stores/ProfilePhotosDialog.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
