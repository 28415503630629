export default [
	{
		path: "/pros/leads-list",
		exact: true,
		policy: "providerAuth",
		component: "LeadsList/LeadsList",
		mui: true,
	},
	{
		path: "/pros/leads",
		exact: true,
		policy: "providerAuth",
		component: "DirectLeads/DirectLeads",
		mui: true,
	},
	{
		path: "/pros/leads/accept/:quoteKey",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "AcceptDirectLead/AcceptDirectLead",
		mui: true,
	},
	{
		path: "/pros/leads/matched-leads",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "MatchedLeads/MatchedLeads",
		mui: true,
	},
	{
		path: "/pros/quote/:leadKey",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "Quote/Quote",
		mui: true,
	},
	// {
	// 	path: "/pros/inbox/chat/:quoteKey",
	// 	exact: true,
	// 	policy: "providerAuth",
	// 	middleware: [
	// 		"providerFunnelOnboardingCheck",
	// 	],
	// 	componentPath: "Dashboard/Pro/Messages",
	// 	bundlePath: "Dashboard/Pro/Messages",
	// 	component: "MessagesChat/MessagesChat",
	// 	mui: true,
	// },
	// {
	// 	path: "/pros/profile",
	// 	exact: true,
	// 	policy: "providerAuth",
	// 	middleware: [
	// 		"providerOnboardingRedirect",
	// 	],
	// 	componentPath: "Dashboard/Pro/Profile/Profile",
	// 	bundlePath: "Dashboard/Pro/Profile",
	// 	component: "Profile/Profile",
	// 	mui: true,
	// },
];
