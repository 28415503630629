import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* webpack-strip-block:removed */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Combo.styles.js"), path: "./@styles/Combo.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/Combo.store.js"), path: "./@stores/Combo.store.js"}];
export const extensions = [{value: () => import(/* webpackPreload: true */ "./@.extension.js"), path: "./@.extension.js"}];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
