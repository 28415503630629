import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";

import Header from "@components/Main/Shared/Header";
import Footer from "@components/Main/Shared/Footer";

import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const { SITE_NAME } = process.env;

const NetworkError = ({ error, message, statusCode = 500 }) => {
	const isProduction = process.env.NODE_ENV === "production";
	let errorMessage = message;

	if (!errorMessage || isProduction) {
		switch (statusCode) {
			case 404:
				errorMessage =
					"We couldn't find the page you were looking for. Go back or return home.";
				break;
			case 503:
				errorMessage =
					"This page is currently unavailable. Try to refresh the page, go back, or return home.";
				break;
			case 500:
			default:
				errorMessage =
					"This page seems to be broken. Try to refresh the page, go back, or return home.";
		}
	}

	return (
		<div>
			<Header />
			<Container maxWidth={"sm"}>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					flexDirection={"column"}
					minHeight={"90vh"}
				>
					<ErrorOutlineIcon
						fontSize="large"
						style={{ color: "#D50000", fontSize: 50 }}
					/>
					<Box component={"h1"} fontSize={54} mt={4}>
						Error {statusCode}
					</Box>
					<Box component={"h2"} fontSize={24} mt={0} textAlign={"center"}>
						{errorMessage}{" "}
						{statusCode === 500 && (
							<>
								Please{" "}
								<Link
									href={`https://help.${SITE_NAME}.com/#contact`}
									color="primary"
									target="_blank"
								>
									report this error to us
								</Link>
								.
							</>
						)}
					</Box>
					<Box mt={8} mb={10}>
						<Button
							href={"/"}
							color={"primary"}
							disableElevation
							size={"large"}
							variant="contained"
						>
							Return home
						</Button>
					</Box>
					{error && (
						<pre style={{ width: "%100", height: "%100" }}>
							{JSON.stringify(error, null, 2)}
						</pre>
					)}
				</Box>
			</Container>

			<Footer />
		</div>
	);
};

export default NetworkError;
