export default [
	{
		path: "/website",
		exact: true,
		ssr: true,
		seo: true,
		component: "Website",
		mui: true,
	},
];
