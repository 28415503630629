import states from "../../../../libs/states";

export default [
	{
		path: "/search",
		ssr: false,
		noIndex: true,
		middleware: ["cityLookUp"],
		component: "Search/Search",
	},
	{
		path: "/search-map",
		ssr: false,
		noIndex: true,
		middleware: ["cityLookUp"],
		component: "SearchMap/SearchMap",
	},
];
