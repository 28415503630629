import omitDeep from "omit-deep";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { isBrowser, getCookieByName } from "@utils/Browser";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { BatchHttpLink } from "apollo-link-batch-http";

const { GRAPHQL_HOST, ENV } = process.env;

export const cleanTypeNameLink = () =>
	new ApolloLink((operation, forward) => {
		if (operation.variables) {
			operation.variables = omitDeep(operation.variables, "__typename");
		}
		return forward(operation).map((data) => {
			return data;
		});
	});

export const httpLink = (fetch) => {
	let uri = GRAPHQL_HOST;
	if (isBrowser) uri = uri.replace("host.docker.internal", "localhost");

	return new HttpLink({
		uri,
		fetch,
		//credentials: "include"
	});
};

export const batchHttpLink = (fetch) => {
	let uri = GRAPHQL_HOST;
	if (isBrowser) uri = uri.replace("host.docker.internal", "localhost");

	return new BatchHttpLink({
		uri,
		fetch,
		//credentials: "include"
	});
};

export const authLink = () =>
	setContext((_, context) => {
		if (!isBrowser) return context;

		const token = getCookieByName("token");
		const _csrf = getCookieByName("_csrfToken");
		const { headers } = context;
		return {
			headers: Object.assign(
				{
					...headers,
					"csrf-token": _csrf,
				},
				token ? { authorization: token } : {}
			),
		};
	});

export const errorLink = () =>
	onError(({ graphQLErrors, networkError, operation }) => {
		if (graphQLErrors) {
			graphQLErrors.map(({ message, locations, path }) =>
				console.error(
					`[GraphQL error]: Message: ${JSON.stringify(
						message,
						null,
						2
					)}, \nLocation: ${JSON.stringify(
						locations,
						null,
						2
					)}, \nPath: ${JSON.stringify(path, null, 2)}`
				)
			);
		}
		if (operation) console.error(`[Operation]: ${operation.operationName}`);
		if (networkError) {
			const { name, statusCode, result } = networkError;
			if (graphQLErrors)
				console.error(
					`[Network error]: ${JSON.stringify({ name, statusCode }, null, 2)}`
				);
			else
				console.error(
					`[Network error]: ${JSON.stringify(
						{ name, statusCode, result },
						null,
						2
					)}`
				);
		}
		if (operation)
			console.warn(
				`[Payload]: ${JSON.stringify(operation.variables, null, 2)}`
			);
	});
