export default [
	{
		path: "/client/account",
		exact: true,
		load: true,
		//policy: "providerAuth",
		component: "Account/Account",
		mui: true,
	},
	{
		path: "/client/account/info",
		//policy: "providerAuth",
		load: true,
		component: "Info/Info",
		mui: true,
	},
	{
		path: "/client/account/notifications",
		load: true,
		//policy: "providerAuth",
		component: "Notifications/Notifications",
		mui: true,
	},
];
