/**
 * Format string for displaying.
 *
 * @returns {String}
 */
String.prototype.display = function () {
	// eslint-disable-line no-extend-native
	if (!this.length) return this;

	let string_array = this.split(" ");

	let first = string_array.shift();
	let last = string_array.pop();

	let array = last ? [first, last] : [first];
	array.forEach(function (item, key) {
		if (key === 0) {
			let word_array = first.split("");
			const fChar = word_array.shift();
			const ucFWord = fChar.toUpperCase() + word_array.join("");

			array[key] = ucFWord;
		} else {
			let word_array = last.split("");
			let fChar = word_array.shift();

			array[key] = fChar.toUpperCase() + ".";
		}
	});

	return array.join(" ");
};

/**
 * Makes the first character of each word upper case.
 *
 * @returns {String}
 */
String.prototype.ucFirst = function () {
	// eslint-disable-line no-extend-native
	if (!this.length) return this;

	const stringArray = this.split(" ");
	stringArray.forEach((word, key) => {
		let wordArray = word.split("");
		let fChar = wordArray.shift();

		fChar = fChar ? fChar.toUpperCase() : "";

		const ucFWord = fChar + wordArray.join("");
		stringArray[key] = ucFWord;
	});

	return stringArray.join(" ");
};
