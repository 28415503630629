export default [
	{
		path: "/providers/:proKey/review",
		exact: true,
		ssr: false,
		noIndex: true,
		middleware: ["cityLookUp"],
		component: "ProReview",
		mui: true,
	},
];
