import states from "../../../../../libs/states";

export default [
	{
		path: `/:state(${states.join("|")})/:city/:serviceSlug/:award(award)`,
		exact: true,
		noIndex: true,
		policy: "providerAuth",
		middleware: ["cityLookUp"],
		component: "Award",
	},
];
