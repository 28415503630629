export default [
	{
		path: "/pros/inbox",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "MessagesList/MessagesList",
		mui: true,
	},
	{
		path: "/pros/inbox/chat/:quoteKey",
		exact: true,
		policy: "providerAuth",
		middleware: ["providerFunnelOnboardingCheck"],
		component: "MessagesChat/MessagesChat",
		mui: true,
	},
	// {
	// 	path: "/pros/leads",
	// 	exact: true,
	// 	policy: "providerAuth",
	// 	bundlePath: "Dashboard/Pro/Leads",
	// 	componentPath: "Dashboard/Pro/Leads",
	// 	component: "DirectLeads/DirectLeads",
	// 	mui: true,
	// },
];
