export default [
	{
		path: "/pros/profile",
		exact: true,
		load: true,
		policy: "providerAuth",
		component: "Profile/Profile",
		mui: true,
	},
];
