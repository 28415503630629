import React from "react";
import loadable from "@loadable/component";

const Header = loadable(() =>
	import("@components/Dashboard/Pro/Funnel/Shared/Header")
);

export default ({ children, ...props }) => (
	<React.Fragment>
		<Header label={"Build a winning profile"} {...props} />
		{children}
	</React.Fragment>
);
