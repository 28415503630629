export default [
	{
		path: "/client/projects",
		policy: "clientAuth",
		component: "Projects/Projects",
		exact: true,
		mui: true,
		load: true,
	},
	{
		path: "/client/projects/:leadKey",
		policy: "clientAuth",
		component: "Project/Project",
		exact: true,
		mui: true,
		load: true,
	},
	{
		path: "/client/projects/:leadKey/status",
		policy: "clientAuth",
		component: "ProjectStatus/ProjectStatus",
		exact: true,
		mui: true,
		load: true,
	},
];
