import React from "react";
import shortid from "shortid";
import PropTypes from "prop-types";
import withConstants from "@framework/src/libs/withConstants";
import useConstants from "@hooks/useConstants";
import { constants, styles } from "./.config";
import useStyles from "@hooks/useStyles";
import { compose } from "recompose";
import withStyles from "@framework/src/libs/withStyles";

import FacebookIcon from "@material-ui/icons/Facebook";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";

const { LINK_HELP, LINK_HELP_CONTACT, BRAND_NAME } = process.env;

const Footer = compose(
	withStyles(styles),
	withConstants(constants)
)(({ thin }) => {
	const classes = useStyles("Footer");

	const { social, nearMeTitle, proGuides, clientGuides, topSubjects } =
		useConstants("FooterViewModel");

	return (
		<React.Fragment>
			<footer>
				<div className={classes.wrapper}>
					{!thin && (
						<Container maxWidth="lg" disableGutters>
							<Box my={12}>
								<Grid container spacing={3}>
									<Grid item xs={6} sm={3}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<div className={classes.footerTitle}>{BRAND_NAME}</div>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href="/"
												>
													Home
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href="/login"
												>
													Log in
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href="/about"
												>
													About
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href="/press"
												>
													Press
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href={LINK_HELP_CONTACT}
												>
													Contact us
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href={LINK_HELP}
												>
													Help
												</Link>
											</Grid>

											<Grid item xs={12}>
												<Box
													display="flex"
													justifyContent="flex-start"
													alignItems="center"
												></Box>
											</Grid>
										</Grid>
									</Grid>

									{topSubjects.length > 0 && (
										<Grid item xs={6} sm={3}>
											<Grid container spacing={3}>
												<Grid item xs={12}>
													<div className={classes.footerTitle}>
														Top services
													</div>
												</Grid>

												{topSubjects.map((subject) => (
													<Grid key={shortid.generate()} item xs={12}>
														<Link
															className={classes.footerLink}
															color={"secondary"}
															underline="none"
															href={subject.link}
														>
															{subject.title}
														</Link>
													</Grid>
												))}
											</Grid>
										</Grid>
									)}

									<Grid item xs={6} sm={3}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<div className={classes.footerTitle}>
													{clientAdjective.plural.ucFirst()}
												</div>
											</Grid>
											{clientGuides.map((guide) => (
												<Grid key={shortid.generate()} item xs={12}>
													<Link
														className={classes.footerLink}
														color={"secondary"}
														underline="none"
														href={guide.link}
													>
														{guide.title}
													</Link>
												</Grid>
											))}

											<Grid item xs={12}>
												<Link
													className={classes.footerLink}
													color={"secondary"}
													underline="none"
													href="/near-me"
												>
													{nearMeTitle}
												</Link>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={6} sm={3}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<div className={classes.footerTitle}>
													{proAdjective.plural.ucFirst()}
												</div>
											</Grid>

											{proGuides.map((guide) => (
												<Grid key={shortid.generate()} item xs={12}>
													<Link
														className={classes.footerLink}
														color={"secondary"}
														underline="none"
														href={guide.link}
													>
														{guide.title}
													</Link>
												</Grid>
											))}
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Container>
					)}

					<Container maxWidth="lg" disableGutters>
						<Box
							component="div"
							bgcolor="grey.50"
							borderTop={thin ? "unset" : "1px solid rgb(221, 221, 221)"}
							py={4}
						>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								flexDirection={{ xs: "column", sm: "row" }}
							>
								<Box
									display={"flex"}
									alignItems={"center"}
									flexWrap={"wrap"}
									lineHeight={1.6}
								>
									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
									>
										&copy; {new Date().getFullYear()} Liaison, Inc.
									</Box>
									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>

									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/terms-of-service"
										target="_blank"
									>
										Terms of Use
									</Link>
									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>

									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/privacy-policy"
										target="_blank"
									>
										Privacy Policy
									</Link>
									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>

									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/accessibility"
										target="_blank"
									>
										Accessibility
									</Link>

									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>
									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/near-me"
									>
										Services
									</Link>
									{/* <Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>

									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/sitemap/locations"
									>
										Locations
									</Link>
									<Box
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										component="span"
										ml={2}
										mr={2}
									>
										&bull;
									</Box>

									<Link
										className={classes.footerLink}
										color={"secondary"}
										underline="none"
										href="/sitemap/profiles"
									>
										Profiles
									</Link> */}
								</Box>
								<Box display={"flex"} alignItems={"center"}>
									{social && social.length > 0 && (
										<Box display={"flex"} alignItems={"center"}>
											{social.map((item) => (
												<Box
													component={"a"}
													display={"flex"}
													href={item.link}
													key={shortid.generate()}
													target="_blank"
													title={item.name.ucFirst()}
												>
													<FacebookIcon color="secondary" />
												</Box>
											))}
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Container>
				</div>
			</footer>
		</React.Fragment>
	);
});

Footer.propTypes = {
	thin: PropTypes.bool,
};

Footer.defaultProps = {
	thin: false,
};

export default Footer;
