export default [
	{
		path: "/pros/catalog",
		exact: true,
		policy: "providerAuth",
		component: "Catalog",
		mui: true,
	},
	{
		path: "/pros/catalog/services",
		exact: true,
		policy: "providerAuth",
		component: "Services/Services",
		mui: true,
	},
	{
		path: "/pros/catalog/services/add",
		exact: true,
		policy: "providerAuth",
		component: "ServicesAddEdit/ServicesAddEdit",
		mui: true,
	},
	{
		path: "/pros/catalog/classes",
		exact: true,
		policy: "providerAuth",
		component: "Classes/Classes",
		mui: true,
	},
	{
		path: "/pros/catalog/classes/class-id",
		exact: true,
		policy: "providerAuth",
		component: "ClassesClass/ClassesClass",
		mui: true,
	},
	{
		path: "/pros/catalog/classes/class-id/schedule-id",
		exact: true,
		policy: "providerAuth",
		component: "ClassesClassSchedule/ClassesClassSchedule",
		mui: true,
	},

	{
		path: "/pros/catalog/classes/add",
		exact: true,
		policy: "providerAuth",
		component: "ClassesAddEdit/ClassesAddEdit",
		mui: true,
	},
	{
		path: "/pros/catalog/classes/schedule",
		exact: true,
		policy: "providerAuth",
		component: "ClassScheduleAddEdit/ClassScheduleAddEdit",
		mui: true,
	},
	{
		path: "/pros/catalog/classes/schedule/pricing",
		exact: true,
		policy: "providerAuth",
		component: "ClassSchedulePricingAddEdit/ClassSchedulePricingAddEdit",
		mui: true,
	},
	{
		path: "/pros/catalog/memberships",
		exact: true,
		policy: "providerAuth",
		component: "Memberships/Memberships",
		mui: true,
	},
	{
		path: "/pros/catalog/memberships/add",
		exact: true,
		policy: "providerAuth",
		component: "MembershipsAddEdit/MembershipsAddEdit",
		mui: true,
	},
];
