import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Account/@module/controller.js"), path: "./Account/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./BusinessDetails/@module/controller.js"), path: "./BusinessDetails/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Locations/@module/controller.js"), path: "./Locations/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Locations/LocationsAddEdit/@module/controller.js"), path: "./Locations/LocationsAddEdit/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./OnlineBooking/@module/controller.js"), path: "./OnlineBooking/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Payments/@module/controller.js"), path: "./Payments/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Resources/@module/controller.js"), path: "./Resources/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Settings/@module/controller.js"), path: "./Settings/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Taxes/@module/controller.js"), path: "./Taxes/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./TipsSettings/@module/controller.js"), path: "./TipsSettings/@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Settings.styles.js"), path: "./@styles/Settings.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Account/@styles/Account.styles.js"), path: "./Account/@styles/Account.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Badges/@styles/Badges.styles.js"), path: "./Badges/@styles/Badges.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Locations/@styles/Locations.styles.js"), path: "./Locations/@styles/Locations.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Notifications/@styles/Notifications.styles.js"), path: "./Notifications/@styles/Notifications.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Payments/@styles/Payments.styles.js"), path: "./Payments/@styles/Payments.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Resources/@styles/Resources.styles.js"), path: "./Resources/@styles/Resources.styles.js"}, {value: () => import(/* webpackPreload: true */ "./SavedReplies/@styles/SavedReplies.styles.js"), path: "./SavedReplies/@styles/SavedReplies.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./Account/@stores/SettingsAccount.store.js"), path: "./Account/@stores/SettingsAccount.store.js"}, {value: () => import(/* webpackPreload: true */ "./Account/Modals/DeactivateAccountModal/@stores/DeactivateAccountModal.store.js"), path: "./Account/Modals/DeactivateAccountModal/@stores/DeactivateAccountModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Account/Modals/EditPasswordModal/@stores/EditPasswordModal.store.js"), path: "./Account/Modals/EditPasswordModal/@stores/EditPasswordModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Account/Modals/EditPersonalInfoModal/@stores/EditPersonalInfoModal.store.js"), path: "./Account/Modals/EditPersonalInfoModal/@stores/EditPersonalInfoModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Modals/UnsubscribeModal/@stores/UnsubscribeModal.store.js"), path: "./Modals/UnsubscribeModal/@stores/UnsubscribeModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Notifications/@stores/Notifications.store.js"), path: "./Notifications/@stores/Notifications.store.js"}, {value: () => import(/* webpackPreload: true */ "./Payments/@stores/Payments.store.js"), path: "./Payments/@stores/Payments.store.js"}, {value: () => import(/* webpackPreload: true */ "./Payments/Modals/EditCreditCardModal/@stores/EditCreditCardModal.store.js"), path: "./Payments/Modals/EditCreditCardModal/@stores/EditCreditCardModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Payments/Modals/RemoveCreditCardModal/@stores/RemoveCreditCardModal.store.js"), path: "./Payments/Modals/RemoveCreditCardModal/@stores/RemoveCreditCardModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Settings/@stores/ProSetting.store.js"), path: "./Settings/@stores/ProSetting.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
