export default [
	{
		path: "/admin/business-model/subscriptions",
		exact: true,
		policy: "superAdminAuth",
		component: "Subscriptions",
		mui: true,
	},
	{
		path: "/admin/business-model/stripe-portal-configurations",
		exact: true,
		policy: "superAdminAuth",
		component: "StripePortalConfigurations",
		mui: true,
	},
	{
		path: "/admin/business-model/groups",
		exact: true,
		policy: "superAdminAuth",
		component: "BusinessModelGroups",
		mui: true,
	},
];
