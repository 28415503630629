import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import {
	staticContext as staticContextElem,
	rootStoreContext,
	pageLoadingContext,
} from "./src/context";
import setStaticContext from "./libs/setStaticContext";
import client from "@framework/apollo/client";
import batch from "@framework/apollo/batch";
import useLoading from "@hooks/useLoading";
import validateStaticContext from "./libs/validateStaticContext";
import { observer } from "mobx-react";
import { isBrowser, searchToObject } from "@utils/Browser";
import NetworkError from "@components/Global/Errors/NetworkError";
import renderServerError from "@framework/libs/renderServerError";
import getResources from "./src/libs/getResources.js";
import useDidMountEffect from "@hooks/useDidMountEffect";

let apolloClient;
let apolloBatchClient;

if (isBrowser) {
	apolloClient = client({
		ssr: false,
		window,
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
			mutate: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
	apolloBatchClient = batch({
		ssr: false,
		window,
		defaultOptions: {
			query: {
				fetchPolicy: "network-only",
				errorPolicy: "all",
			},
			mutate: {
				fetchPolicy: "no-cache",
				errorPolicy: "all",
			},
		},
	});
}

class Queue {
	constructor() {
		this.queue = [];
		this.running = false;
	}
	add(fn, onLoading) {
		this.queue.push({ fn, onLoading });
		if (this.queue.length === 1) this.run();
	}
	run() {
		if (this.running || this.queue.length === 0) return;
		this.running = true;
		const { fn, onLoading } = this.queue[this.queue.length - 1];
		this.queue = [];
		fn()
			.then(() => {
				if (this.queue.length) {
					this.running = false;
					this.run();
				} else {
					onLoading(false);
					this.running = false;
				}
			})
			.catch((err) => {
				console.log(err);
				if (this.queue.length) {
					this.running = false;
					this.run();
				}
			});
	}
}

const queue = new Queue();

const Controller = observer(
	({ routes, hash, statusCode, staticContext, ...props }) => {
		const location = useLocation();
		const rootStore = useContext(rootStoreContext);
		const route = routes.find((route) => matchPath(location.pathname, route));
		if (!route) return props.children;

		const [error, setError] = useState();
		const [LoadingTemplate, onLoading, loadingStore] = useLoading({
			show: !route.ssr,
			_id: `${route.path}${hash}`,
		});

		const [routesState, setRoutesState] = useState(
			routes.reduce((result, _route) => {
				if (_route.path === route.path && route.ssr) {
					rootStore.setStores({
						stores: Object.keys(route.stores)
							.filter((key) => !route.stores[key].__preInitialized)
							.reduce(
								(result, key) =>
									Object.assign(result, { [key]: route.stores[key] }),
								{}
							),
						staticContext,
					});
				}
				return Object.assign(result, {
					[_route.path]: {
						..._route,
						staticContext,
					},
				});
			}, {})
		);

		const init = (route) =>
			async function () {
				const routeState = routesState[route.path];
				const { params } = matchPath(location.pathname, route);
				const req = {
					path: location.pathname,
					context: {},
					apolloClient: apolloClient,
					apolloBatchClient: apolloBatchClient,
					url: location.pathname,
					query: searchToObject(location.search),
					params,
					route: routeState,
					staticContext,
					...location,
				};

				const res = {
					redirect: (path) => {
						window.location.href = path;
					},
					renderServerError: (err) => {
						setError(renderServerError(err, req.staticContext));
					},
				};

				req.setStaticContext = setStaticContext(routeState)(req, res);
				res.setStaticContext = setStaticContext(routeState)(req, res);

				const next = () => () => {
					const staticContext = Object.assign(
						{},
						routeState.staticContext,
						req.staticContext
					);

					rootStore.setStores({
						stores: routeState.stores,
						libs: routeState.libs,
						staticContext,
						force: true,
					});

					setRoutesState(
						Object.assign({}, routesState, {
							[routeState.path]: Object.assign({}, routeState, {
								staticContext,
							}),
						})
					);
				};

				onLoading(true);

				await getResources({ currentRoute: route });

				const responses = await Promise.all(routeState.controller);
				await Promise.all(
					responses
						.flat()
						.map(
							(controller) =>
								new Promise((resolve) => controller(req, res, resolve))
						)
				);

				(await validateStaticContext(routeState)(req, res, next))();
			};

		useDidMountEffect(() => {
			onLoading(true);
			queue.add(init(route), onLoading);
		}, [location.pathname]);

		useEffect(() => {
			if (route.ssr || !route.controller) {
				onLoading(false);
				return;
			}
			onLoading(true);
			queue.add(init(route), onLoading);
		}, []);

		const backgroundProps = route.background ? route.background.props : {};
		const Background = route.background
			? route.background.value
			: (props) => <React.Fragment>{props.children}</React.Fragment>;

		return (
			<React.Fragment>
				<staticContextElem.Provider
					value={routesState[route.path].staticContext}
				>
					<rootStoreContext.Provider value={rootStore}>
						{error ? (
							<NetworkError {...error} route={route} />
						) : (
							<Background {...backgroundProps}>
								<pageLoadingContext.Provider value={loadingStore.show}>
									{route.load ? (
										<LoadingTemplate>{props.children}</LoadingTemplate>
									) : (
										!loadingStore.show && (
											<React.Fragment>{props.children}</React.Fragment>
										)
									)}
								</pageLoadingContext.Provider>
							</Background>
						)}
					</rootStoreContext.Provider>
				</staticContextElem.Provider>
			</React.Fragment>
		);
	}
);

export default Controller;
