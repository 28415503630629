export default (route) =>
	(req, res) =>
	async (data = {}, validate = true) => {
		// try {
		// 	if (!req.controllerCount) req.controllerCount = 1;
		// 	else req.controllerCount = req.controllerCount + 1;
		// 	if (route.model && route.model[req.controllerCount - 1] && validate) {
		// 		Object.assign(
		// 			req.staticContext,
		// 			await route.model[req.controllerCount - 1](data)
		// 		);
		// 		return;
		// 	}

		req.staticContext = Object.assign({}, req.staticContext || {}, data);
		// } catch (err) {
		// 	await res.renderServerError(err);
		// }
	};
