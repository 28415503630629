export default [
	{
		path: "/admin/settings",
		exact: true,
		policy: "superAdminAuth",
		component: "AdminSetting",
		mui: true,
	},
	{
		path: "/admin/settings/services",
		exact: true,
		policy: "adminAuth",
		component: "AdminSettingServices",
		mui: true,
	},
	{
		path: "/admin/settings/categories",
		exact: true,
		policy: "superAdminAuth",
		component: "AdminSettingCategories",
		mui: true,
	},
	{
		path: "/admin/settings/affinities",
		exact: true,
		policy: "adminAuth",
		component: "AdminSettingAffinities",
		mui: true,
	},
	{
		path: "/admin/settings/combos",
		exact: true,
		policy: "superAdminAuth",
		component: "AdminSettingCombos",
		mui: true,
	},
	{
		path: "/admin/settings/locations",
		exact: true,
		policy: "superAdminAuth",
		component: "AdminSettingLocations",
		mui: true,
	},
];
