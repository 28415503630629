export default [
    {
        path: "/login",
        exact: true,
        ssr: false,
        component: "Login",
        mui: true,
    },
    {
        path: "/reset-password",
        exact: true,
        ssr: false,
        component: "ResetPassword/ResetPassword",
        mui: true,
    }
];