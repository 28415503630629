/**
 * String representation of time since a given date.
 *
 * @param {SimpleDate.jsx} date
 * @returns {String}
 */
Date.prototype.timeSince = function (date) { // eslint-disable-line no-extend-native
  let seconds = 0;
  const now = this.getTime();
  let _date = date;

  if (_date instanceof Date) _date = _date.getTime();

  if (now > _date) seconds = Math.floor((now - _date) / 1000);
  else seconds = Math.floor((now - _date) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    if (interval > 1) return interval + " years ago";
    return interval + " year ago";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    if (interval > 1) return interval + " months ago";
    return interval + " month ago";
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    if (interval > 1) return interval + " days ago";
    return interval + " day ago";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    if (interval > 1) return interval + " hours ago";
    return interval + " hour ago";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    if (interval > 1) return interval + " minutes ago";
    return interval + " minute ago";
  }
};

/**
 * Find out number of days in a given month.
 *
 * @returns {Number}
 */
Date.prototype.getDaysInMonth = function () { // eslint-disable-line no-extend-native
  const year = this.getFullYear();
  let month = this.getMonth();
  // On a leap year the number of days in Feb change from 28 to 29
  // Rules for leap year:
  // 1) Every 4th year is a leap year
  // 2) However, every 100th year is NOT a leap year
  // 3) Every 400th year is a leap year
  const leap_year = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  // Months are in integers starting with 'Jan': 0
  if (month === 1 && !leap_year) {
    return 28
  }
  if (month === 1 && leap_year) {
    return 29
  }
  if (month > 6) {
    month -= 1;
  }
  if (month % 2 === 0) {
    return 31
  }
  else {
    return 30
  }
};

/**
 * Finds out hour in range of 1 - 12.
 *
 * @returns {Number}
 */
Date.prototype.get12Hours = function () { // eslint-disable-line no-extend-native
  const hrs = this.getHours();
  if (hrs === 0) return 12;
  if (hrs > 12) return hrs - 12;

  return hrs;
};

/**
 * Return "am" or "pm" suffix.
 *
 * @returns {String}
 */
Date.prototype.getSuffix = function () { // eslint-disable-line no-extend-native
  return (this.getHours() >= 12) ? 'pm' : 'am'
};


/**
 * Return the numeric day of the week, 0 being Monday.
 *
 * @returns {Number}
 */
Date.prototype.getWeekday = function () { // eslint-disable-line no-extend-native
  // day starts 'Sun': 0 but our calendar starts Mon
  let dayOfWeek = this.getDay() - 1;
  if (dayOfWeek < 0) {
    dayOfWeek += 7;
  }

  return dayOfWeek;
};

/**
 * Return date in simple human readable format.
 *
 * @returns {String}
 */
Date.prototype.readable = function () { // eslint-disable-line no-extend-native
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

  const month = this.getMonth();
  const dayOfWeek = this.getDay();
  const dayOfMonth = this.getDate();
  const year = this.getFullYear();

  return days[dayOfWeek] + ' ' + months[month] + ' ' + dayOfMonth + ', ' + year;
};

/**
 * Returns the three letter month abbreviation.
 *
 * @returns {String}
 */
Date.prototype.getMonthName = function () { // eslint-disable-line no-extend-native
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const month = this.getMonth();

  return months[month];
};
