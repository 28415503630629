import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./Project/@module/controller.js"), path: "./Project/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Projects/@module/controller.js"), path: "./Projects/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ProjectStatus/@module/controller.js"), path: "./ProjectStatus/@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./Project/@styles/Project.styles.js"), path: "./Project/@styles/Project.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Project/@styles/SendProject.styles.js"), path: "./Project/@styles/SendProject.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Projects/@styles/Projects.styles.js"), path: "./Projects/@styles/Projects.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./Project/@stores/Project.store.js"), path: "./Project/@stores/Project.store.js"}, {value: () => import(/* webpackPreload: true */ "./Project/@stores/Pros.store.js"), path: "./Project/@stores/Pros.store.js"}, {value: () => import(/* webpackPreload: true */ "./ProjectStatus/@stores/ProjectStatus.store.js"), path: "./ProjectStatus/@stores/ProjectStatus.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
