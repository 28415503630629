import React, { useContext } from "react";
import { constantsContext } from "../context";
import Mark from "markup-js";
import { isObject, isString, isArray } from "@utils/Browser";
import DataObjectParser from "dataobject-parser";

const useConstants = (...names) => {
	const constants = useContext(constantsContext);
	const ctx = isObject(names[names.length - 1]) ? names.pop() : undefined;

	if (names.length === 0) return constants;

	const everyIsArray = names
		.filter((name) => constants[name])
		.every((name) => isArray(constants[name]));
	const everyIsObject = names
		.filter((name) => constants[name])
		.every((name) => isObject(constants[name]));

	if (!everyIsObject && !everyIsArray && names.length > 1)
		throw new Error(
			"When using multiple constant files, all must be either arrays or objects"
		);

	if (everyIsArray) {
		var val = [];
	}

	if (everyIsObject) {
		var val = {};
	}

	for (let name of names) {
		if (!constants[name]) continue;
		if (isArray(val)) val = [...val, ...constants[name]];
		if (isObject(val)) val = { ...val, ...constants[name] };
	}

	if (ctx) {
		const v = DataObjectParser.untranspose(val);
		for (let k in v) {
			if (!isString(v[k])) continue;
			v[k] = Mark.up(v[k], ctx);
		}
		return DataObjectParser.transpose(v).data();
	}

	return val;
};

export default useConstants;
