import { batchHttpLink, cleanTypeNameLink, authLink, errorLink } from "./link";

import { ApolloClient } from "apollo-client";
import {
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { from } from "apollo-link";
// import introspectionQueryResultData from "../../../fragmentTypes.json";

// const fragmentMatcher = new IntrospectionFragmentMatcher({
// 	introspectionQueryResultData,
// });
export default ({
	fetch,
	ssr = false,
	window,
	defaultOptions = {
		watchQuery: {
			fetchPolicy: "no-cache",
			errorPolicy: "ignore",
		},
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "none",
		},
		mutate: {
			fetchPolicy: "no-cache",
			errorPolicy: "none",
		},
	},
}) => {
	return new ApolloClient({
		link: from([
			//cleanTypeNameLink(),
			errorLink(),
			authLink(),
			batchHttpLink(fetch),
		]),
		cache: new InMemoryCache({
			addTypename: true,
			//fragmentMatcher,
		}).restore(window ? window.__APOLLO_STATE__ : undefined),
		ssrMode: ssr,
		defaultOptions,
	});
};
