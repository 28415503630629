import states from "../../../../libs/states";

export default [
	{
		path: `/:state(${states.join("|")})/:city/:serviceSlug`,
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "Combo",
		mui: true,
	},
	// {
	// 	path: `/ny/new-york/math-tutors/award`,
	// 	exact: true,
	// 	ssr: true,
	// 	seo: true,
	// 	noIndex: true,
	// 	middleware: ["cityLookUp"],
	// 	component: "Award/Award",
	// 	mui: true,
	// },
];
