import states from "../../../../libs/states";

export default [
	{
		path: `/:state(${states.join("|")})`,
		exact: true,
		ssr: true,
		seo: true,
		component: "State",
		middleware: ["cityLookUp"],
		mui: true,
	},
];
