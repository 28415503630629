import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}];
export const models = [{value: () => import(/* webpackPreload: true */ "./@module/model.js"), path: "./@module/model.js"}];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Login.styles.js"), path: "./@styles/Login.styles.js"}];
export const constants = [{value: () => import(/* webpackPreload: true */ "./@constants/bettercare/LoginViewModel.json"), path: "./@constants/bettercare/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/dealflow/LoginViewModel.json"), path: "./@constants/dealflow/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/fash/LoginViewModel.json"), path: "./@constants/fash/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/homeguide/LoginViewModel.json"), path: "./@constants/homeguide/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawful/LoginViewModel.json"), path: "./@constants/lawful/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawnmasters/LoginViewModel.json"), path: "./@constants/lawnmasters/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lessons/LoginViewModel.json"), path: "./@constants/lessons/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/rvgo/LoginViewModel.json"), path: "./@constants/rvgo/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/talo/LoginViewModel.json"), path: "./@constants/talo/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/thervo/LoginViewModel.json"), path: "./@constants/thervo/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/trustedcare/LoginViewModel.json"), path: "./@constants/trustedcare/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/tutors/LoginViewModel.json"), path: "./@constants/tutors/LoginViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/vety/LoginViewModel.json"), path: "./@constants/vety/LoginViewModel.json"}];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/Login.store.js"), path: "./@stores/Login.store.js"}, {value: () => import(/* webpackPreload: true */ "./@stores/ResetPassword.store.js"), path: "./@stores/ResetPassword.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
