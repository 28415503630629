export default [
	{
		path: "/client/inbox",
		exact: true,
		component: "InboxList/InboxList",
		load: true,
	},
	{
		path: "/client/inbox/:leadKey/:quoteKey",
		exact: true,
		component: "Inbox/Inbox",
		load: true,
		ssr: true,
		strict: false,
	},
];
