export default [
	{
		path: "/client/payments",
		exact: true,
		component: "Payments",
		load: true,
	},
	{
		path: "/client/payments/settings",
		exact: true,
		component: "PaymentsSettings/PaymentsSettings",
		load: true,
	},
	{
		path: "/client/payments/invoice",
		exact: true,
		component: "ClientInvoice/ClientInvoice",
		load: true,
		//ssr: true,
	},
	{
		path: "/client/payments/make-payment",
		exact: true,
		component: "InvoicePayment/InvoicePayment",
		load: true,
	},
];
