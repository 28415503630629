export default (theme) => ({
	wrapper: {
		background: theme.palette.grey[50],
		borderTop: "1px solid rgb(221, 221, 221)",
		padding: "0px 60px",
		[theme.breakpoints.down("sm")]: {
			padding: "0px 16px",
		},
	},
	footerTitle: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: 8,
	},
	footerLink: {
		"fontSize": 14,
		"&:hover": {
			color: "#000",
		},
	},
});
