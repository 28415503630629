export default [
	{
		path: "/pros/team",
		exact: true,
		policy: "providerAuth",
		component: "Team",
		mui: true,
	},
	{
		path: "/pros/team/availability",
		exact: true,
		policy: "providerAuth",
		component: "TeamAvailability/TeamAvailability",
		mui: true,
	},
];
