export default [
	{
		path: "/pros/profile/authorize-background-check",
		exact: true,
		policy: "providerAuth",
		middleware: [
			//"providerOnboardingRedirect",
		],
		component: "BackgroundCheck/BackgroundCheck",
		mui: true,
	},
];
