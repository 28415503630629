export default [
	{
		path: "/pros/contacts",
		exact: true,
		policy: "providerAuth",
		component: "Contacts",
		mui: true,
	},
	{
		path: "/pros/contacts/automated-messages",
		exact: true,
		policy: "providerAuth",
		component: "AutomatedMessages/AutomatedMessages",
		mui: true,
	},
	{
		path: "/pros/contacts/notifications-list",
		exact: true,
		policy: "providerAuth",
		component: "NotificationsList/NotificationsList",
		mui: true,
	},
	{
		path: "/pros/contacts/members",
		exact: true,
		policy: "providerAuth",
		component: "Members/Members",
		mui: true,
	},
];
