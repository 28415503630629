export default [
	{
		path: "/pro",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "Pro",
		mui: true,
	},
	{
		path: "/pro/online-tutoring-jobs",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "OnlineJobs/OnlineJobs",
		mui: true,
	},
	{
		path: "/pro/math-tutor-jobs",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "MathJobs/MathJobs",
		mui: true,
	},
	{
		path: "/pro/service/listing",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "JobListing/JobListing",
		mui: true,
	},
	{
		path: "/pro/how-to-become-a-tutor",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "BecomeTutor/BecomeTutor",
		mui: true,
	},
	{
		path: "/pro/cleaning-leads",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "CleaningLeads/CleaningLeads",
		mui: true,
	},
	{
		path: "/pro/tarot-reader-jobs",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "TarotJobs/TarotJobs",
		mui: true,
	},
	{
		path: "/guitar-lessons/guitar-chords",
		exact: true,
		//ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "GuitarChords/GuitarChords",
		mui: true,
	},
	{
		path: "/guitar-lessons/guitar-lessons-for-beginners/",
		exact: true,
		//ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "GuitarBeginners/GuitarBeginners",
		mui: true,
	},
	{
		path: "/yoga-classes/yoga-poses/",
		exact: true,
		//ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "YogaPoses/YogaPoses",
		mui: true,
	},
];
