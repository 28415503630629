import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Hottie.styles.js"), path: "./@styles/Hottie.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/Landing.styles.js"), path: "./@styles/Landing.styles.js"}];
export const constants = [{value: () => import(/* webpackPreload: true */ "./@constants/bettercare/LandingViewModel.json"), path: "./@constants/bettercare/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/dealflow/HeaderViewModel.json"), path: "./@constants/dealflow/HeaderViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/fash/LandingViewModel.json"), path: "./@constants/fash/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/homeguide/LandingViewModel.json"), path: "./@constants/homeguide/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawful/LandingViewModel.json"), path: "./@constants/lawful/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lawnmasters/LandingViewModel.json"), path: "./@constants/lawnmasters/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/lessons/LandingViewModel.json"), path: "./@constants/lessons/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/rvgo/HeaderViewModel.json"), path: "./@constants/rvgo/HeaderViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/talo/LandingViewModel.json"), path: "./@constants/talo/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/thervo/LandingViewModel.json"), path: "./@constants/thervo/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/trustedcare/LandingViewModel.json"), path: "./@constants/trustedcare/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/tutors/LandingViewModel.json"), path: "./@constants/tutors/LandingViewModel.json"}, {value: () => import(/* webpackPreload: true */ "./@constants/vety/HeaderViewModel.json"), path: "./@constants/vety/HeaderViewModel.json"}];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
