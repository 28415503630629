import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./Reviews/@module/controller.js"), path: "./Reviews/@module/controller.js"}];
export const models = [{value: () => import(/* webpackPreload: true */ "./Reviews/@module/model.js"), path: "./Reviews/@module/model.js"}];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Reviews.styles.js"), path: "./@styles/Reviews.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./Reviews/@stores/ReviewsList.store.js"), path: "./Reviews/@stores/ReviewsList.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
