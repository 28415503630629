export default [
	{
		path: "/pros/payments",
		exact: true,
		policy: "providerAuth",
		component: "Payments",
		mui: true,
	},
	{
		path: "/pros/payments/settings",
		exact: true,
		policy: "providerAuth",
		component: "PaymentsSettings/PaymentsSettings",
		mui: true,
	},
	{
		path: "/pros/payments/disputes",
		exact: true,
		policy: "providerAuth",
		component: "Disputes/Disputes",
		mui: true,
	},
];
