import React, { useContext } from "react";
import { rootStoreContext, staticContext } from "../context";
import required from "../../libs/required";

export default (stores = {}, options = {}) => {
	return (Component = required`Component`) => {
		return (props) => {
			const rootStore = useContext(rootStoreContext);
			const ctx = useContext(staticContext);
			rootStore.addStores(
				Object.assign(options, { stores, staticContext: ctx })
			);
			return (
				<rootStoreContext.Provider value={rootStore}>
					<Component {...props} />
				</rootStoreContext.Provider>
			);
		};
	};
};
