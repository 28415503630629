import { observable, action, computed, toJS } from "mobx";

import AuthenticationService from "@services/Authentication";
const { match } = require("path-to-regexp");

export const authenticationService = new AuthenticationService({});
// export const initialState = {
// 	_id: "",
// 	authenticated: false,
// 	type: -1,
// };

export default class AccountStore {
	@observable account = {};

	constructor(rootStore, staticContext = { app: {}, data: {} }) {
		this.rootStore = rootStore;
		this.staticContext = staticContext;
		this.update(AccountStore.initialState(staticContext).account);
		this.logout = this.logout.bind(this);
	}

	@computed get isAuthenticated() {
		return toJS(this.account.authenticated);
	}
	@computed
	get type() {
		return toJS(this.account.type);
	}

	static initialState(staticContext = {}) {
		const { account } = staticContext.app || {};
		const authenticated = account && !!account.authenticated;
		const type = account ? account.type : "NONE";
		const _id = account ? account._id : "";
		const provider = account ? account.provider : "";

		return { account: { authenticated, type, _id, provider } };
	}

	getEmail() {
		if (!this.isAuthenticated) return;
		return this.account.email;
	}

	redirectPath(type) {
		if (!type) type = this.account.type;
		switch (type) {
			case "CLIENT":
				return "/";
			case "USER":
			case "PROVIDER":
				return "/pros";
			case "ADMIN":
			case "SUPERADMIN":
				return "/admin/leads";
			case "WRITER":
				return "/admin/faq";
			default:
				return window.location.origin;
		}
	}

	get isClient() {
		if (!this.account) return false;
		if (!this.account.type === "CLIENT" || !this.account.type === "USER")
			return false;
		if (this.account.type === "CLIENT") return true;
		const url = window.location.href;
		const clientDashboard = match("/client/(.*)", {
			encode: encodeURI,
			decode: decodeURIComponent,
		});
		if (clientDashboard(url)) return true;
		return false;
	}

	get isProvider() {
		if (!this.account) return false;
		if (!this.account.type === "PROVIDER" || !this.account.type === "USER")
			return false;
		if (this.account.type === "PROVIDER") return true;
		const url = window.location.href;
		const proDashboard = match("/pros/(.*)", {
			encode: encodeURI,
			decode: decodeURIComponent,
		});
		if (proDashboard(url)) return true;
		return false;
	}

	redirect(type, nativeRedirect) {
		window.location.href = this.redirectPath(type);
	}

	@action
	update(account) {
		Object.assign(this.account, account);
	}

	@action
	login(account, redirect = true) {
		this.update(Object.assign(account, { authenticated: true }));

		let nativeRedirect = false;
		if (["CLIENT", "USER", "PROVIDER"].includes(account.type))
			nativeRedirect = true;

		if (redirect) this.redirect(account.type, nativeRedirect);
	}

	logout() {
		authenticationService
			.logout()
			.then(() => {
				this.update(this.constructor.initialState(this.staticContext));
				window.location.href = "/";
				// window.location.reload();
			})
			.catch((err) => {
				throw err;
			});
	}
}
