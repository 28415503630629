import states from "../../../../libs/states";

export default [
	{
		path: `/:state(${states.join("|")})/:city`,
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "City",
		mui: true,
	},
];
