export default [
	{
		path: "/costs",
		exact: true,
		ssr: true,
		seo: true,
		component: "Costs/Costs",
		middleware: ["cityLookUp"],
	},
	{
		path: "/courses",
		exact: true,
		ssr: true,
		seo: true,
		component: "Courses/Courses",
		middleware: ["cityLookUp"],
	},
	{
		path: "/:folder/:slug",
		exact: true,
		ssr: true,
		seo: true,
		component: "Article/Content",
		middleware: ["cityLookUp"],
	},
];
