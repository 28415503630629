import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}];
export const models = [{value: () => import(/* webpackPreload: true */ "./@module/model.js"), path: "./@module/model.js"}];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Account.styles.js"), path: "./@styles/Account.styles.js"}, {value: () => import(/* webpackPreload: true */ "./Notifications/@styles/Notifications.styles.js"), path: "./Notifications/@styles/Notifications.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./@stores/ClientProfile.store.js"), path: "./@stores/ClientProfile.store.js"}, {value: () => import(/* webpackPreload: true */ "./Info/Modals/DeactivateAccountModal/@stores/DeactivateAccountModal.store.js"), path: "./Info/Modals/DeactivateAccountModal/@stores/DeactivateAccountModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Info/Modals/EditPasswordModal/@stores/EditPasswordModal.store.js"), path: "./Info/Modals/EditPasswordModal/@stores/EditPasswordModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Info/Modals/EditPersonalInfoModal/@stores/EditPersonalInfoModal.store.js"), path: "./Info/Modals/EditPersonalInfoModal/@stores/EditPersonalInfoModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Modals/UnsubscribeModal/@stores/UnsubscribeModal.store.js"), path: "./Modals/UnsubscribeModal/@stores/UnsubscribeModal.store.js"}, {value: () => import(/* webpackPreload: true */ "./Notifications/@stores/Notifications.store.js"), path: "./Notifications/@stores/Notifications.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
