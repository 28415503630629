import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Classes/@module/controller.js"), path: "./Classes/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ClassesAddEdit/@module/controller.js"), path: "./ClassesAddEdit/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ClassesClass/@module/controller.js"), path: "./ClassesClass/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ClassesClassSchedule/@module/controller.js"), path: "./ClassesClassSchedule/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ClassScheduleAddEdit/@module/controller.js"), path: "./ClassScheduleAddEdit/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ClassSchedulePricingAddEdit/@module/controller.js"), path: "./ClassSchedulePricingAddEdit/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Memberships/@module/controller.js"), path: "./Memberships/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./MembershipsAddEdit/@module/controller.js"), path: "./MembershipsAddEdit/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Services/@module/controller.js"), path: "./Services/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./ServicesAddEdit/@module/controller.js"), path: "./ServicesAddEdit/@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/Catalog.styles.js"), path: "./@styles/Catalog.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/CatalogList.styles.js"), path: "./@styles/CatalogList.styles.js"}];
export const constants = [];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
