export default [
	{
		path: "/:serviceSlug",
		exact: true,
		ssr: true,
		seo: true,
		middleware: ["cityLookUp"],
		component: "NearMe",
		mui: true,
	},
	// {
	// 	path: "/guitar-lessons/guitar-chords",
	// 	exact: true,
	// 	//ssr: true,
	// 	seo: true,
	// 	middleware: ["cityLookUp"],
	// 	component: "GuitarChords/GuitarChords",
	// 	mui: true,
	// },
];
