import Bundle from "@framework/Bundle";

export const routes = require.context(
	"./",
	true,
	/.*\/@module($|\/routes\.(js))/
);

/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-START */
/* REMOVE-FOR-CLIENT-SSR-START */
export const controllers = [{value: () => import(/* webpackPreload: true */ "./@module/controller.js"), path: "./@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./AcceptDirectLead/@module/controller.js"), path: "./AcceptDirectLead/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./DirectLeads/@module/controller.js"), path: "./DirectLeads/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./LeadsList/@module/controller.js"), path: "./LeadsList/@module/controller.js"}, {value: () => import(/* webpackPreload: true */ "./Quote/@module/controller.js"), path: "./Quote/@module/controller.js"}];
export const models = [];
/* REMOVE-FOR-CLIENT-SSR-END */
export const libs = [{value: () => import(/* webpackPreload: true */ "./@libs/Card.js"), path: "./@libs/Card.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/Lead.js"), path: "./@libs/Lead.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/Provider.js"), path: "./@libs/Provider.js"}, {value: () => import(/* webpackPreload: true */ "./@libs/Quote.js"), path: "./@libs/Quote.js"}];
export const styles = [{value: () => import(/* webpackPreload: true */ "./@styles/LeadBlock.styles.js"), path: "./@styles/LeadBlock.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/Leads.styles.js"), path: "./@styles/Leads.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/LeadsList.styles.js"), path: "./@styles/LeadsList.styles.js"}, {value: () => import(/* webpackPreload: true */ "./@styles/Quote.styles.js"), path: "./@styles/Quote.styles.js"}];
export const constants = [{value: () => import(/* webpackPreload: true */ "./Quote/@constants/fash/FirstMessageExample.json"), path: "./Quote/@constants/fash/FirstMessageExample.json"}, {value: () => import(/* webpackPreload: true */ "./Quote/@constants/homeguide/FirstMessageExamples.json"), path: "./Quote/@constants/homeguide/FirstMessageExamples.json"}, {value: () => import(/* webpackPreload: true */ "./Quote/@constants/lessons/FirstMessageExamples.json"), path: "./Quote/@constants/lessons/FirstMessageExamples.json"}, {value: () => import(/* webpackPreload: true */ "./Quote/@constants/thervo/FirstMessageExample.json"), path: "./Quote/@constants/thervo/FirstMessageExample.json"}, {value: () => import(/* webpackPreload: true */ "./Quote/@constants/trustedcare/FirstMessageExamples.json"), path: "./Quote/@constants/trustedcare/FirstMessageExamples.json"}, {value: () => import(/* webpackPreload: true */ "./Quote/@constants/tutors/FirstMessageExample.json"), path: "./Quote/@constants/tutors/FirstMessageExample.json"}];
export const loadable = require.context("./", true, /\.(loadable)\.(js)$/);
export const stores = [{value: () => import(/* webpackPreload: true */ "./AcceptDirectLead/@stores/AcceptDirectLead.store.js"), path: "./AcceptDirectLead/@stores/AcceptDirectLead.store.js"}, {value: () => import(/* webpackPreload: true */ "./AcceptDirectLead/@stores/AcceptDirectLeadDetails.store.js"), path: "./AcceptDirectLead/@stores/AcceptDirectLeadDetails.store.js"}, {value: () => import(/* webpackPreload: true */ "./AcceptDirectLead/@stores/AcceptDirectLeadPanel.store.js"), path: "./AcceptDirectLead/@stores/AcceptDirectLeadPanel.store.js"}, {value: () => import(/* webpackPreload: true */ "./AcceptDirectLead/@stores/LeadDeclineDialog.store.js"), path: "./AcceptDirectLead/@stores/LeadDeclineDialog.store.js"}, {value: () => import(/* webpackPreload: true */ "./DirectLeads/@stores/DirectLeads.store.js"), path: "./DirectLeads/@stores/DirectLeads.store.js"}, {value: () => import(/* webpackPreload: true */ "./DirectLeads/@stores/DirectLeadsBadges.store.js"), path: "./DirectLeads/@stores/DirectLeadsBadges.store.js"}, {value: () => import(/* webpackPreload: true */ "./DirectLeads/@stores/DirectLeadsCard.store.js"), path: "./DirectLeads/@stores/DirectLeadsCard.store.js"}, {value: () => import(/* webpackPreload: true */ "./MatchedLeads/@stores/MatchedLeads.store.js"), path: "./MatchedLeads/@stores/MatchedLeads.store.js"}, {value: () => import(/* webpackPreload: true */ "./Quote/@stores/Quote.store.js"), path: "./Quote/@stores/Quote.store.js"}, {value: () => import(/* webpackPreload: true */ "./Quote/@stores/QuoteLeadDetails.store.js"), path: "./Quote/@stores/QuoteLeadDetails.store.js"}, {value: () => import(/* webpackPreload: true */ "./Quote/@stores/QuotePanel.store.js"), path: "./Quote/@stores/QuotePanel.store.js"}, {value: () => import(/* webpackPreload: true */ "./Quote/Modals/@stores/Dialog.store.js"), path: "./Quote/Modals/@stores/Dialog.store.js"}];
export const extensions = [];
export const background = require.context("./", true, /\.(background)\.(js)$/);
/* webpack-strip-block:removed */
/* webpack-strip-block:removed */
/* REMOVE-FOR-SERVER-END */
