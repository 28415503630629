export default [
	{
		path: `/terms-of-service`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Terms/Terms",
		mui: true,
	},
	{
		path: `/terms-of-service/terms-damages`,
		exact: true,
		ssr: true,
		seo: true,
		component: "TermsDamages/TermsDamages",
		mui: true,
	},
	{
		path: `/terms-of-service/terms-health`,
		exact: true,
		ssr: true,
		seo: true,
		component: "TermsHealth/TermsHealth",
		mui: true,
	},
	{
		path: `/terms-of-service/terms-legal`,
		exact: true,
		ssr: true,
		seo: true,
		component: "TermsLegal/TermsLegal",
		mui: true,
	},
	{
		path: `/terms-of-service/terms-pos`,
		exact: true,
		ssr: true,
		seo: true,
		component: "TermsPos/TermsPos",
		mui: true,
	},
	{
		path: `/terms-of-service/terms-payments`,
		exact: true,
		ssr: true,
		seo: true,
		component: "TermsPayments/TermsPayments",
		mui: true,
	},
	{
		path: `/privacy-policy`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Privacy/Privacy",
		mui: true,
	},
	{
		path: `/reviews`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Reviews/Reviews",
		mui: true,
	},
	{
		path: `/safety`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Safety/Safety",
		mui: true,
	},
	{
		path: `/about`,
		exact: true,
		ssr: true,
		seo: true,
		component: "About/About",
		mui: true,
	},
	{
		path: `/about/person`,
		exact: true,
		ssr: true,
		seo: true,
		component: "About/AboutPerson/AboutPerson",
		mui: true,
	},
	{
		path: `/press`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Press/Press",
		mui: true,
	},
	{
		path: `/our-selection-process`,
		exact: true,
		ssr: true,
		seo: true,
		component: "SelectionProcess/SelectionProcess",
		mui: true,
	},
	{
		path: `/accessibility`,
		exact: true,
		ssr: true,
		seo: true,
		component: "Accessibility/Accessibility",
		mui: true,
	},
];
